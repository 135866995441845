<template lang="pug">
.template-chooser-custom-templates(ref="block")
  om-heading.mb-6.font-size-3(v-if="listedTemplates.length" h3) {{ $t('customTheme.chooser.moreTemplates') }}
  Skeleton(v-if="$apollo.loading")
  .row
    template(v-for="(template, index) in listedTemplates")
      .col-12.mb-6(v-if="!isActiveFilters && index === 30")
        filter-banner
      .col-4.mb-6
        TemplateBox(
          @inited="updateDimensions"
          @observable="addObservable($event.$el)"
          :dimensions="boxDimensions"
          :template="template"
          heap-event="new-selector-campaign-more-templates"
        )
  .template-chooser-custom-templates-more-handler(ref="loadingHandler" style="width: 100%")
</template>

<script>
  import { mapState } from 'vuex';
  import { debounce } from 'lodash-es';
  import GET_CHOOSER_FILTERED_TEMPLATES from '@/graphql/GetChooserFilteredTemplates.gql';
  import observableCollectionMixin from '@/mixins/observableCollection';
  import ssrMixin from '@/mixins/ssr';
  import homepageBlockMixin from '../../mixins/homepageBlock';
  import childRouteMixin from '../../mixins/childRoute';
  import templateBoxParentMixin from '../../mixins/templateBoxParent';
  import filterHelperMixin from '../../mixins/filterHelper';

  export default {
    name: 'CustomTemplates',
    components: {
      Skeleton: () => import('@/components/TemplateChooser/components/Skeleton.vue'),
      TemplateBox: () => import('@/components/TemplateChooser/components/TemplateBox.vue'),
      FilterBanner: () => import('@/components/TemplateChooser/components/FilterBanner.vue'),
    },
    mixins: [
      homepageBlockMixin,
      childRouteMixin,
      ssrMixin,
      observableCollectionMixin,
      templateBoxParentMixin,
      filterHelperMixin,
    ],
    data: () => ({
      templates: [],
      templateCount: 12,
      isObserverSupported: true,
      observer: null,
      ssrBoxSelector: '.template-chooser-custom-templates .template-thumbnail-box',
    }),
    computed: {
      ...mapState(['accountType']),
      listedTemplates() {
        if (this.templateCount > this.templates.length) return this.templates;
        return this.templates.slice(0, this.templateCount);
      },
    },
    watch: {
      'filter.types': function () {
        this.onFilterChange();
      },
      'filter.goals': function () {
        this.onFilterChange();
      },
      search() {
        this.onFilterChange();
      },
    },
    apollo: {
      templates: {
        query: GET_CHOOSER_FILTERED_TEMPLATES,
        variables() {
          const filter = {
            ...this.filter,
            search: this.search,
            category: [],
          };
          return { filter, type: this.accountType };
        },
        update({ templates }) {
          const all = templates.searchResults
            ? templates.searchResults
            : Object.values(templates.byTheme).flat();
          if (!this.isObserverSupported) this.templateCount = all.length;
          return all;
        },
        result() {
          this.initObserver();
          this.$emit('loaded', this);
        },
      },
    },
    beforeDestroy() {
      if (this.intersectionObserver) {
        this.intersectionObserver.disconnect();
        this.intersectionObserver = null;
      }
      if (this.observer) {
        this.observer.disconnect();
        this.observer = null;
      }
    },
    mounted() {
      if (this.$refs.block) {
        this.$refs.block.__comp__ = this;
        this.addObservable(this.$refs.block);
      }
    },
    methods: {
      onIntersecting() {
        this.updateDimensions();
      },
      onNotIntersecting() {},
      initObserver() {
        if (
          this.observer ||
          !this.isObserverSupported ||
          (!('IntersectionObserver' in window) &&
            !('IntersectionObserverEntry' in window) &&
            !('intersectionRatio' in window.IntersectionObserverEntry.prototype))
        ) {
          this.isObserverSupported = false;
          return;
        }
        const options = {
          root: document.querySelector('body'),
          rootMargin: '300px 0px',
          threshold: 1.0,
        };
        this.observer = new IntersectionObserver(this.onIntersection, options);
        this.observer.observe(this.$refs.loadingHandler);
      },
      onIntersection(entries) {
        entries.forEach(({ isIntersecting }) => {
          if (isIntersecting) this.templateCount += 24;
        });
      },
      onFilterChange: debounce(function () {
        this.templateCount = 0;
        this.$apollo.queries.templates.refetch().then(() => {
          this.templateCount = 24;
          if (this.search) {
            this.$emit('templateCount', { type: 'custom', count: Number(this.templates.length) });
          }
        });
      }, 50),
      getPossibleTemplates() {
        return this.templates;
      },
    },
  };
</script>
